import React from "react";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Title from "../components/title";
import { graphql } from "gatsby";
import { Seo } from "../components/seo";
import Button from "../components/button";
import { getCaseURL } from "../utils/get-asset-url";

export default function CaseTemplate({ data, pageContext }) {
	const item = data.directus.cases[0];
	const { next, previous } = pageContext;

	return (
		<Layout>
			<main>
				<Title subtitle="Portfolio Case" backButtonTo="/portfolio">
					{item.title}
				</Title>
				<section className="bg-off-white pb-12">
					<div className="container mx-auto md:flex justify-center gap-20">
						<div className="mb-4 md:mb-0">
							<h5 className="font-extrabold text-xs uppercase mb-1">
								Opdrachtgever
							</h5>
							<p>{item.client}</p>
						</div>
						<div className="mb-4 md:mb-0">
							<h5 className="font-extrabold text-xs uppercase mb-1">
								Diensten
							</h5>
							<p>
								{item.services.map((service, i) => (
									<span key={i}>
										{(i ? ", " : "") + service}
									</span>
								))}
							</p>
						</div>
						<div className="mb-4 md:mb-0">
							<h5 className="font-extrabold text-xs uppercase mb-1">
								Jaren actief
							</h5>
							<p>{item.years}</p>
						</div>
					</div>
				</section>
				<section>
					<GatsbyImage
						className="w-full h-auto"
						alt="Spark"
						image={getImage(item.cover.imageFile)}
						loading="eager"
					/>
				</section>

				{item.introduction && item.body && (
					<article className="prose py-56 mx-auto">
						<p className="text-2xl leading-relaxed">
							{item.introduction}
						</p>
						<p className="text-lg">{item.body}</p>
					</article>
				)}

				<section className="my-8">
					<div className="container mx-auto md:columns-2 gap-y-8">
						{item.images.map((image, i) => (
							<div key={image.directus_files_id.id}>
								<GatsbyImage
									className="mb-4"
									alt={item.title}
									image={getImage(
										image.directus_files_id.imageFile
									)}
								/>
							</div>
						))}
					</div>
				</section>
				<section className="py-24">
					<div className="container mx-auto flex justify-between">
						<div>
							{next && (
								<Button
									flipArrow={true}
									dark={true}
									to={getCaseURL(next.slug)}
								>
									{next.title}
								</Button>
							)}
						</div>
						<div>
							{previous && (
								<Button
									dark={true}
									to={getCaseURL(previous.slug)}
								>
									{previous.title}
								</Button>
							)}
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
}

export const Head = ({ location, data }) => (
	<Seo
		pathname={location.pathname}
		pageSubtitle="Portfolio Case"
		pageTitle={data.directus.cases[0].title}
		title={`${data.directus.cases[0].title} - SPARK - fotografie voor cultuur, sport en entertainment`}
		description={`Bekijk ons fotografie portfolio case ${data.directus.cases[0].title}.`}
	/>
);

export const query = graphql`
	query CaseQuery($id: String) {
		directus {
			cases(filter: { id: { _eq: $id } }) {
				slug
				title
				introduction
				client
				services
				years
				cover {
					id
					imageFile {
						childImageSharp {
							gatsbyImageData(width: 1600, quality: 75)
						}
					}
				}
				images {
					directus_files_id {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 800, quality: 75)
							}
						}
					}
				}
			}
		}
	}
`;
