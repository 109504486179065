import React from "react";
import Button from "./button";
import Heading from "./heading";
import SubHeading from "./subheading";

const Title = ({ children, subtitle, backButtonTo }) => {
	return (
		<section className="bg-off-white text-center py-28 md:py-56 relative">
			{backButtonTo && (
				<Button
					className="absolute top-4 left-4"
					dark={true}
					flipArrow={true}
					to={backButtonTo}
				>
					Terug
				</Button>
			)}

			{subtitle && <SubHeading>{subtitle}</SubHeading>}
			<Heading className="text-5xl md:text-8xl">{children}</Heading>
		</section>
	);
};

export default Title;
